//-- font
@import '../../fonts/stylesheet.css';

//-- buma & custom variables
@import './settings/settings';

//-- bulma basics
@import 'node_modules/bulma/sass/base/all';
@import 'node_modules/bulma/sass/utilities/all';
@import 'node_modules/bulma/sass/helpers/all';

//-- bulma classes
@import 'node_modules/bulma/sass/components/card';
@import 'node_modules/bulma/sass/components/navbar';
@import 'node_modules/bulma/sass/components/tabs';
@import 'node_modules/bulma/sass/elements/other';
@import 'node_modules/bulma/sass/elements/button';
@import 'node_modules/bulma/sass/elements/container';
@import 'node_modules/bulma/sass/elements/table';
@import 'node_modules/bulma/sass/grid/columns';
@import 'node_modules/bulma/sass/layout/hero';
@import 'node_modules/bulma/sass/layout/section';

//-- nfx modules
@import 'node_modules/@creative-labs/font-scale/lib/font-scale';
@import 'node_modules/@creative-labs/text-flow/lib/text-flow';

//-- custom global
@import './global/global';
@import './global/typography';

//-- custom components
@import './components/article';
@import './components/button';
@import './components/box';
@import './components/card';
@import './components/floating-container';
@import './components/footer';
@import './components/header';
@import './components/img';
@import './components/inpage-nav';
@import './components/mobile-inpage-nav';
@import './components/navbar';
@import './components/offcanvas';
@import './components/subpage-nav';
@import './components/table';
@import './components/teaser';
