// -- colors
$primary: #f6c304;
$secondary: #eff6f7;
$tertiary: #fff;
$black: #000;
$white: #fff;
$light-gray: #f0f0f0;
$dark-gray: #707070;
$selected: $primary;
$unselected: #707070;
$shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

// -- Typography

$font-primary: bree-serif;
$font-secondary: open-sans;

$text-medium: 18px;
$text-small: 16px;

// nfx typo sizes

$nfx-typography-heading-sizes: (
  1: (
    min: 40px,
    max: 65px,
  ),
  2: (
    min: 30px,
    max: 50px,
  ),
  3: (
    min: 20px,
    max: 35px,
  ),
  4: (
    min: 20px,
    max: 30px,
  ),
  5: (
    min: 20px,
    max: 24px,
  ),
);

$bold: bold;
$normal: normal;

$line-height-heading: 1.2;
$line-height-body: 1.5;

// -- Custom Settings
$section-gap: 2rem;
$offcanvas-breakpoint: 2500px;

//navbar
$navbar-height: 5rem;
$burger-line-width: 40px;
$navbar-dropdown-background-color: $dark-gray;

// -- Bulma Settings

// Initial Var
$link: $black;

// Section

// Button

// Box

// Breadcrumbs

// Card

// Navbar
$navbar-item-active-color: $selected;
$navbar-item-img-max-height: 3.5rem;
$navbar-item-hover-background-color: $selected;
$navbar-dropdown-item-hover-background-color: $selected;
$navbar-dropdown-item-hover-color: inherit;

// Modal

// Table
$table-striped-row-even-background-color: $secondary;

// Tabs
$tabs-boxed-link-active-background-color: $black;
$tabs-link-color: $black;
$tabs-link-active-color: $white;

// Other
$default-transition: all 0.2s ease-out;
