.mobile-inpage-nav {
  background: $white;
  @include from($offcanvas-breakpoint) {
    display: none;
  }

  .icon {
    margin-left: 20px;
  }

  .tabs {
    ul {
      max-width: 100%;
      border: 0;
      box-shadow: 0 0 5px rgba($black, 0.1);

      .current-headline {
        width: 90%;

        a {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          border-radius: 0;
          border: 0;
        }
      }
    }
  }

  .dropdown {
    &.rotate {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.tab-content > div {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
