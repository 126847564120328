.header-container {
  max-width: 1700px;
  margin: auto;
  position: relative;
}

.hero {
  background: $white;

  img {
    position: static;
    width: 100%;
    height: auto;
    object-fit: cover;

    @include from($desktop) {
      position: absolute;
      height: 100%;
      width: 50%;
      top: 0;
      right: 0;
      margin: unset;
    }
  }
}

.button-container {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 0.5rem;
  }

  @include until($tablet) {
    justify-content: center;
  }
}
