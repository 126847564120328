// -- typo classes
body {
  font-family: $font-secondary;
  color: $black;
  line-height: $line-height-body;
}

a {
  color: $black;
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  font-family: $font-primary;
  color: $black;
  line-height: $line-height-heading;
}

.heading-4 {
  &.is-nav {
    color: $unselected;
  }
}

.heading-5 {
  &.is-nav {
    color: $unselected;
  }
}

.heading-6 {
  font-size: $text-medium;
}

.text {
  &.is-static-medium {
    font-size: $text-medium;
  }

  &.is-static-small {
    font-size: $text-small;
  }
}

.is-bold {
  font-weight: $bold;
}

.is-white {
  color: $white;
}
