html,
body {
  max-width: 100vw;
  scroll-behavior: smooth;
}

// -- new
.is-hover-primary {
  *:hover {
    color: $primary;
  }
}

.link-hover {
  a:hover {
    color: $primary;
  }
}

.text-container {
  padding-top: 0.6rem;
}

.has-shadow {
  box-shadow: $shadow;
}

.is-shadowless {
  box-shadow: none;
}

.background-primary {
  background: $primary;
}

.background-secondary {
  background: $secondary;
}

.background-tertiary {
  background: $tertiary;
}

.has-bulletpoint {
  display: list-item;
  list-style-type: disc;
  list-style-position: outside;
}

.is-hidden-offcanvas {
  @include from($offcanvas-breakpoint) {
    display: none;
  }
}

.is-sticky {
  position: sticky;
  top: 0;
  z-index: 10;
}

.is-sticky-navbar {
  position: sticky;
  top: $navbar-height;
  z-index: 10;
}

.has-scroll-margin {
  scroll-margin-top: 9rem;
}

.pimcore_area_paragraph + .pimcore_area_headline {
  .heading-1,
  .heading-2,
  .heading-3,
  .heading-4,
  .heading-5,
  .heading-6 {
    margin-top: 1.5em;
  }
}
