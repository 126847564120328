.offcanvas {
  padding: 1.5rem 0;
  display: none;

  @include from($offcanvas-breakpoint) {
    float: left;
    display: inline-block;
    margin-top: 3rem;
    min-height: 600px;
    max-width: 400px;
    position: sticky;
    left: 0;
    top: $navbar-height;
  }

}
