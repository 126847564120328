.cta-button {
  @extend .button;

  color: $white;
  padding: 0.5rem;
  background-color: $black;
  border: 0;
  border-radius: 0;

  &.is-same-size {
    width: 250px;
  }

  &.is-outlined {
    background-color: $white;
    border: 1px solid $black;
    color: $black;

    &:hover {
      color: $black;
    }

    &:focus {
      color: $black;
    }
  }

  &:hover {
    color: $white;
  }

  &:focus {
    color: $white;
    box-shadow: unset !important;
  }
}

.nav-button {
  @extend .button;

  border-radius: 0;
  background: $light-gray;
  color: $black;
  border: 0;
  font-weight: bold;
  font-size: 0.8rem;

  &.is-active {
    transition: 0.5s;
    background: $primary;
    color: $black;
  }

  &.is-large {
    font-size: $text-medium;
    padding: 0.7rem;
    width: 100%;
    border-radius: 30px;
  }

  &:hover {
    color: $black;
    transition: 0.5s;
    background: $primary;

    a {
      color: $black;
    }
  }

  &:focus {
    border: inherit;
    box-shadow: unset !important;
  }
}

.teaser-button {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  min-width: 46px;
  width: 100%;
  border-radius: 50%;
  background-color: $primary;
}
