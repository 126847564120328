.nav-section {
  height: $navbar-height;
  z-index: 15;
}

.navbar-menu {
  text-align: right;
}

.navbar-burger {
  background-color: $white;

  span {
    width: $burger-line-width;
    left: calc((100% - $burger-line-width) / 2);
  }

  span:nth-child(1) {
    top: calc(50% - 12px);
  }

  span:nth-child(2) {
    top: calc(50%);
  }

  span:nth-child(3) {
    top: calc(50% + 12px);
  }

  &.is-active {
    span:nth-child(1) {
      transform: translateY(12px) rotate(45deg);
    }

    span:nth-child(3) {
      transform: translateY(-12px) rotate(-45deg);
    }
  }
}

.navbar-dropdown {
  background-color: $white;
  border-top: 0;
  @include from($desktop) {
    box-shadow: 0 10px 20px rgba($black, 0.2);
  }

  a:before {
    content: '›';
    margin: 0 0.8em 0 0;
  }

  .navbar-item.active {
    background: transparent;
    font-weight: bold;
  }
}

.navbar {
  align-items: center !important;
}

.navbar-item {
  align-items: center !important;
  transition: $default-transition;

  &.active {
    background: $primary;

    @include until($desktop) {
      .navbar-dropdown.is-hidden-touch {
        display: block !important;
      }
    }
  }
}

.navbar-link {
  transition: $default-transition;
}
