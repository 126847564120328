.subpage-nav {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .text-container {
    padding: 20px;
    hyphens: auto;
  }

  a {
    color: $black;

    &::after {
      content: '→';
      padding-left: 0.5rem;
    }
  }
}

.pimcore_area_subpage-nav {
  height: 100%;
}
