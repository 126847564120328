.teaser-wrapper {
  @include from($desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
  }
}

.teaser-container {
  width: 100%;
  position: relative;

  .teaser-img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }

  &.is-small {
    display: flex;
    gap: 1.25em;
    flex-direction: row;
    padding: 0.5rem 0;
    border-top: 1px solid $black;
    align-items: center;

    @include until($desktop) {
      &:last-child {
        border-bottom: 1px solid $black;
      }
    }

    @include from($desktop) {
      &:last-child,
      &:nth-last-child(2) {
        border-bottom: 1px solid $black;
      }
    }

    .text-container {
      padding: unset;
      display: flex;
      align-items: center;
    }

    .img-container {
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;

      @include until($desktop) {
        display: none;
      }

      picture {
        display: block;
        height: 0;
      }

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center center;
        border-radius: 50%;
        /*  transform: translateY(-50%); */
      }
    }

    .teaser-button-container {
      width: 50px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin-left: auto;

      .teaser-button {
        min-width: 0;
        width: 30px;

        .img {
          width: 18px;
        }
      }
    }
  }
}

.has-border-top {
  border-top: 1px solid $black;
}
