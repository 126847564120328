.img-container {
  height: 100%;
  display: flex;

  &.v-centered {
    align-items: center;
  }

  &.h-centered {
    justify-content: center;
  }

  img {
    object-fit: cover;
  }
}
